import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cuetstyle.css";
import Card from "./Card.jsx";
import { useState, useEffect } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SubscriptionCard from "../../Payment/SubscriptionCard.jsx";
import Cookies from "js-cookie";
import baseUrl from "../../baseUrl.js";
import { Navigation } from "swiper";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
// import documentLink from '../../../../public/assets/document/exampatternofCUET.docx';

const CUETCourse = ({ course, type }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      // console.log("tokenValue", Cookies.get("token"));
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);
  const [pack, setPack] = useState([]);
  const [title, setTitle] = useState("");
  const [packInc, setPackInc] = useState([]);
  const [packTitle, setPackTitle] = useState("");
  const [slideIndex, setSlideIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [boolaen, setBoolaen] = useState({
    alertTxt: false,
    modalValid: false,
    disDomain: false,
    showDomain: false,
    showLang: false,
    packAlrt: false,
  });
  const fallbackMessage = "Currently, No Subscription pack is available. ";
  const [show, setShow] = useState(false);
  const [packTemp, setPackTemp] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [checkout, setCheckout] = useState({});
  const [selectedCourse, setSelectedCourse] = useState("1");
  const isSmallScreen = window.innerWidth <= 900;

  const submitModal = (obj, checkVal, tempArr) => {
    tempArr = tempArr ? tempArr : packTemp;
    let countspecialInstruction = tempArr[0][obj].filter(
      (sub) => sub.specialInstruction === true
    ).length;
    if (countspecialInstruction >= tempArr[0][checkVal]) {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: false,
        modalValid: true,
      }));
    } else {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: true,
        modalValid: false,
      }));
    }
  };
  const breakpoints = {
    375: {
      slidesPerView: 1,
    },
    790: {
      slidesPerView: 1,
    },
    1150: {
      slidesPerView: 1,
    },
    1550: {
      slidesPerView: 1,
    },
    1900: {
      slidesPerView: 1,
    },
  };
  useEffect(() => {
    handleSubscriptionChange(selectedCourse);
  }, [selectedCourse]);
  const handleSubscriptionChange = (id) => {
    fetch(
      `${baseUrl()}/df/getAllSubscriptionPacks/${type === "UG" ? "1" : "9"}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          Authorization: `${Cookies.get("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPack(data.Data);
        setIsPrimium((prev) => ({
          ...prev,
          msg: data.message,
          status: data.status,
        }));
        // const payBtn = document.querySelector(".payBtn");
        // payBtn.style.pointerEvents = "none";
        // payBtn.innerText = "Free";
      })
      .catch((e) => console.log("subscription 01 CUET", e));
  };
  const closeModal = () => {
    setPackInc([]);
    setBoolaen((prev) => ({
      ...prev,
      showDomain: false,
      showLang: false,
      modalValid: false,
    }));
  };
  const [isPrimium, setIsPrimium] = useState({ msg: "", status: 400 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [filterToggle, setFilterToggle] = useState(false);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleClickSubscription = () => {
    window.location.href = "/online-subscription";
  }

  return (
    <div className={isSmallScreen ? "" : "cuetcontainer"} style={{ overflowX: "hidden" }}>
      <div className="cuethead">
        <h1>{course}</h1>
        <p>HOME/CUET({type}) COURSE</p>
      </div>
      {
        isSmallScreen ? (
          <Row>
            <Col md={6} sm={12} xs={12} className="faq_lay2 p-0">
              <div className="course_details_ctn">

                <div className="cuetctn">
                  <h1>CUET({type}) Exam</h1>
                  <p style={{ textAlign: "justify" }}>
                    Common University Entrance Test (CUET) is being introduced for
                    admission into all UG/PG programmes in all Central Universities
                    for academic sessions under the Ministry of Education (MoE). It
                    was introduced in 2022.
                  </p>
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
                  className="courseimg"
                  alt="CUET"
                />
                <div className="textcontent">
                  <h4>Description</h4>
                  <p style={{ textAlign: "justify" }}>
                    {type == "PG" ? `Common University Entrance Test (CUET) is being introduced for admission
    into UG/PG programme in Central and participating Universities of the country.
    The Common University Entrance Test (CUET) will provide a common platform and
    equal opportunities to candidates across the country , especially those from North-East
    and from rural and other remote areas and help to establish better connect with
    the Universities. A single Examination will enable the candidates to cover a wide outreach and be part of
    the admission process to various Central Universities.` :
                      `Common University Entrance Test (CUET) is being introduced 
      for admission into all UG/PG programmes in all Central Universities
      for academic session  under the Ministry of Education (MoE).It was
      introduced for the first time in 2022. A single examination will 
      enable the candidates to cover a wide outreach & be part of the admission
      process to various Central Universities & participating Universities of India.`}
                  </p>
                </div>
                <div className="textcontent">
                  <h4>Eligibility</h4>
                  <p style={{ textAlign: "justify" }}>
                    {type == "PG" ? `For appearing in the CUET (PG) 2024, there is no age limit for the candidates.
    The candidates who have passed the bachelor degree/ equivalent examination or
    appearing in 2024 irrespective of their age can appear in CUET (PG) 2024 examination.
    However, the candidates will be required to fulfil the age criteria of the
    University in which they are desirous of taking admission.` :
                      `For appearing in CUET (UG) there is no age limit for the candidates. The candidates who have passed the className XII/ equivalent examination or are appearing, irrespective of their age can appear in CUET (UG) examination. However, the candidates will be required to fulfil the age criteria (if any) of the University (ies) in which they are desirous of taking admission.`}
                  </p>
                </div>

                {type == "PG" ?
                  (<div className="textcontent">
                    <h4>Mode Of Exam</h4>
                    <p style={{ textAlign: "justify" }}>
                      <strong>CUET (PG) 2024 will be conducted in Computer Based Test (CBT) mode only.</strong>
                    </p>
                  </div>) : (
                    <div className="textcontent">
                      <h4>Mode Of Exam</h4>
                      <p style={{ textAlign: "justify" }}>
                        <strong>Hybrid mode (Pen & Paper + CBT)</strong>
                      </p>
                      <p>Note: It will depend on the candidate count after the registration is complete.</p>
                    </div>
                  )}



                {type == "PG" ?
                  (
                    <div className="textcontent">
                      <h4>Medium of Question Papers</h4>
                      <p style={{ textAlign: "justify" }}>
                        The medium of Question Paper for CUET (PG) 2024 will be English and Hindi (Bilingual) except for languages, M.Tech Higher Sciences and Acharya papers (except Hindu studies, Baudha Darshan and Indian Knowledge System).
                      </p>
                    </div>
                  ) : (
                    <div className="textcontent">
                      <h4>Medium of Examination</h4>
                      <p style={{ textAlign: "justify" }}>
                        The tests (other than "language" test) are offered in 13 languages i.e, Assamese,
                        Bengali, English, Gujarati,Hindi,Kannada,Malayalam,Marathi,Odiya,Punjabi,Tamil,
                        Telegu & Urdu. A candidate is required to opt for one of the specified languages as
                        the medium of the paper, as per desired University's eligibility criteria while applying.
                        Medium is not the same as the 'language' opted as the component of the test. 'Language'
                        test is for assessing the proficiency/skills of the candidate in the language opted by
                        him/her & the question paper in respect of the same will be available to the candidate in the medium opted by him/her only. The question paper of the 'language' test will not bilingual.
                      </p>
                    </div>
                  )}
                {type == "PG" ? <></> : (
                  <div className="textcontent">
                    <h4>Choice of Test Paper</h4>
                    <p style={{ textAlign: "justify" }}>
                      Maximum 06 Test papers (04 or 05 domain subjects including General Test and 01 or 02 languages).
                    </p>
                    <h4>Medium of Qusetion Paper</h4>
                    <p style={{ textAlign: "justify" }}>
                      The tests (other than "language" test) are offered in 13 languages .
                      <ul>
                        <li>Assamese</li>
                        <li>Bengali</li>
                        <li>English</li>
                        <li>Gujarati</li>
                        <li>Hindi</li>
                        <li>Kannada</li>
                        <li>Malayalam</li>
                        <li>Marathi</li>
                        <li>Odia</li>
                        <li>Punjabi</li>
                        <li>Tamil</li>
                        <li>Telegu</li>
                        <li>Urdu</li>
                      </ul>
                      A candidate is required to opt for one of the specified languages as the medium of the paper, as per desired University's eligibility criteria while applying.
                    </p>
                  </div>
                )
                }

                <div
                  className="modeofexamctn"
                  style={{
                    padding: "1rem 2rem",
                    width: "100%",
                    background: "#71269C44",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h4>{type == "PG" ? "Marking Scheme" : "Marking Scheme"}</h4>
                  <div
                    className="lictn"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {type == "PG" ? (<ul>
                      <li> - Each question carries 04 (four) marks.</li>
                      <li> - For each correct response, candidate will get 04 (four) marks.</li>
                      <li> - For each incorrect response, 01(one) mark will be deducted from the total score.</li>
                      <li> - Un-answered/un-attempted response will be given no marks.</li>
                    </ul>) : (
                      <ul>
                        <li> - For each correct answer, five (5) marks will be rewarded and for each wrong answer -1 will be deducted. There is no mark for unattempt questions.</li>
                      </ul>
                    )
                    }
                  </div>
                </div>


                {type == "PG" ? (<div
                  className="modeofexamctn"
                  style={{
                    padding: "1rem 2rem",
                    width: "100%",
                    background: "#71269C44",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h4>Syllabus of CUET (PG) 2024</h4>
                  <div
                    className="lictn"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <ul>
                      <li> - Entrance syllabus for question papers would be available in the official website <a href="https://pgcuet.samarth.ac.in"><span style={{ color: "#71269C" }}>https://pgcuet.samarth.ac.in</span></a> </li>
                    </ul>
                  </div>
                </div>) : (
                  <div
                    className="modeofexamctn"
                    style={{
                      padding: "1rem 2rem",
                      width: "100%",
                      background: "#71269C44",
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h4>Syllabus of CUET (UG) 2024</h4>
                    <div
                      className="lictn"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <ul>
                        <li> - The syllabus for subjects is available on the NTA website <a href="https://exams.nta.ac.in/CUET-UG/"><span style={{ color: "#71269C" }}> https://exams.nta.ac.in/CUET-UG/.</span> </a></li>
                      </ul>
                    </div>
                  </div>
                )}
                {type == "PG" ? (<div className="table-responsive-sm">
                  <h4>Schedule of Examination</h4>
                  <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                    <tbody>
                      <tr>
                        <td>Dates of Examination </td>
                        <td colSpan="3">11 March 2024 to 28 March 2024</td>
                      </tr>
                      <tr>
                        <td>Mode of Examination </td>
                        <td colSpan="3"> Computer Based Test (CBT) mode only</td>
                      </tr>
                      <tr>
                        <th scope="col">Shift</th>
                        <th scope="col">Shift-1</th>
                        <th scope="col">Shift-2</th>
                        <th scope="col">Shift-3</th>
                      </tr>
                      <tr>
                        <td>Timing of Examination</td>
                        <td>9.00 a.m to 10.45 a.m</td>
                        <td>12.45 p.m to 2.30 p.m</td>
                        <td>4.30 p.m to 6.15 p.m</td>
                      </tr>
                      <tr>
                        <td>Duration of Examination </td>
                        <td>1 Hour and 45 minutes (105 Minutes) </td>
                        <td>1 Hour and 45 minutes (105 Minutes) </td>
                        <td>1 Hour and 45 minutes  (105 Minutes) </td>
                      </tr>
                      <tr>
                        <td>Total number of Questions </td>
                        <td colSpan="3">The question paper will have 75 questions. </td>
                      </tr>
                      <tr>
                        <td colSpan="4">Note:  Compensatory time for PwBD candidates of 20 minutes for every one-hour examination. </td>
                      </tr>
                    </tbody>
                  </table>
                </div>) : (
                  <div className="table-responsive-sm">
                    <h4>Schedule of Examination</h4>
                    <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                      <tbody>
                        <tr>
                          <td>Dates of Examination </td>
                          <td>Between 15 May 2024 & 31 May 2024 (Dates may vary
                            depending on the National Election Schedule)</td>
                        </tr>
                        <tr>
                          <td>Pattern of Question Paper </td>
                          <td>Objective type Multiple Choice Questions (MCQs)</td>
                        </tr>
                        <tr>
                          <td>Total number of Questions </td>
                          <td>40 out of 50 questions for all test papers & 50 out of 60
                            questions for General Test</td>
                        </tr>
                        <tr>
                          <td>Duration of Examination </td>
                          <td>45 minutes for all test papers except
                            Mathematics/Applied
                            Mathematics,Accountancy,Physics,Chemistry,Economics
                            ,Computer Science/Informatics Practices,& General Test
                            which would be 60 minutes.</td>
                        </tr>
                        <tr>
                          <td>Slots</td>
                          <td>The examination will be conducted on multiple days in two
                            or three shifts per day, depending on the number of
                            candidates & their combinations.</td>
                        </tr>
                        <tr>
                          <td colSpan="2">NOTE: Compensatory time for PwBD Candidates of 20 minutes for each hour examination will be
                            given.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {type == "PG" ? (<div className="textcontent">
                  <h4>Structure of Question Papers for CUET(PG) 2024:
                  </h4>
                  <ul>
                    <li>- Candidates can choose maximum of 4 (four) question paper codes.</li>
                    <li>- There would be no General Test with each paper.</li>
                    <li>- The candidate can choose General Test paper as a subject. The comprehension part of these papers would be English or Hindi as chosen by the candidate during registration.
                    </li>
                  </ul>
                </div>) : (
                  // <div
                  //   className="modeofexamctn"
                  //   style={{
                  //     padding: "1rem 2rem",
                  //     width: "100%",
                  //     background: "#71269C44",
                  //     borderRadius: "4px",
                  //     display: "flex",
                  //     flexDirection: "column",
                  //   }}
                  // >
                  //   <div className="d-flex align-items-center">
                  //     <h4>Please click here to know more</h4>
                  //     <a href={"../../../../public/assets/document/exampatternofCUET.docx"} target="_blank" download={"exampatternofCUET.docx"}>&nbsp; - Open Document</a>
                  //   </div>

                  // </div>
                  <></>
                )}
              </div>
            </Col>
            <Col md={6} sm={12} xs={12} className="faq_lay2 pt-4">
              <div>
                {type = "UG" ? <>
                  <div className="blog-bg mb-3 p-3" style={{ height: "auto", borderRadius: "10px", width: "100%" }}>
                    <p style={{ textAlign: "left" }}>Unlock boundless knowledge from the comfort of your home with our online classes subscription – where learning knows no limits!</p>
                    <button type="button" className="sub_button1 btn mt-2 " onClick={handleClickSubscription}>
                      CUET Online Subscription
                    </button>
                  </div>
                </> : <>
                  <div>
                    {pack && pack.length !== 0 ? (
                      <Swiper
                        allowTouchMove={false}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation={true}
                        modules={[Navigation]}
                        onSlideChange={() => {
                          setSlideIndex(slideIndex + 1);
                        }}
                        className="custom-slider"
                        id="course-xyz-page"
                      >
                        {pack?.map((item, index) => {
                          let domainCount1 = item.avlDomainSubjects?.filter(
                            (x) => x.specialInstruction === true
                          ).length;
                          let langCount1 = item.avlLangSubjects?.filter(
                            (x) => x.specialInstruction === true
                          ).length;
                          let selLang = item.avlLangSubjects?.filter(
                            (x) => x.selection === true
                          ).length;
                          let selDomain = item.avlDomainSubjects?.filter(
                            (x) => x.selection === true
                          ).length;

                          let domainSub1 = [];
                          item.avlDomainSubjects?.filter((x) => {
                            if (x.specialInstruction || x.selection) {
                              domainSub1.push(x.topicName);
                              return x.topicName;
                            }
                          });

                          return (
                            <SwiperSlide key={index}>
                              <SubscriptionCard
                                item={item}
                                domainCount1={domainCount1}
                                langCount1={langCount1}
                                selLang={selLang}
                                selDomain={selDomain}
                                domainSub1={domainSub1}
                                setPackTitle={setPackTitle}
                                setTitle={setTitle}
                                setPackInc={setPackInc}
                                setPage={setPage}
                                setBoolaen={setBoolaen}
                                submitModal={submitModal}
                                setCheckout={setCheckout}
                                setCouponDetails={setCouponDetails}
                                setShow={setShow}
                                closeModal={closeModal}
                                pack={pack}
                                setPackTemp={setPackTemp}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <div className="fallback-message-subscriptions-container">
                        <p>{fallbackMessage}</p>
                        {/* <p>Test From Ravi Sharma</p> */}
                      </div>
                    )}
                  </div>
                </>}

                <div className="p-4">
                  <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
                  <ul style={{ fontSize: "14px" }}>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice paper</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Mock exam(replica of NTA interface)</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live classes by experts</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Study materials & previous year’s papers pdf</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Mock registration process</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Delhi University form fill - up Process</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live Quiz</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Self - performance analysis</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Individual Ranking</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Doubt resolution</span></small></strong></li>
                  </ul>
                </div>
                <hr className="bg-secondary " />
                <div className="p-4">
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaRegStar style={{ color: "#FFC107" }} />
                  <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
                </div>
              </div>
            </Col>
          </Row>
        ) : (<div className="cuetcontent">
          <div className="course_details_ctn">

            <div className="cuetctn">
              <h1>CUET({type}) Exam</h1>
              <p style={{ textAlign: "justify" }}>
                Common University Entrance Test (CUET) is being introduced for
                admission into all UG/PG programmes in all Central Universities
                for academic sessions under the Ministry of Education (MoE). It
                was introduced in 2022.
              </p>
            </div>

            <img
              src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
              className="courseimg"
              alt="CUET"
            />
            <div className="textcontent">
              <h4>Description</h4>
              <p style={{ textAlign: "justify" }}>
                {type == "PG" ? `Common University Entrance Test (CUET) is being introduced for admission
                into UG/PG programme in Central and participating Universities of the country.
                The Common University Entrance Test (CUET) will provide a common platform and
                equal opportunities to candidates across the country , especially those from North-East
                and from rural and other remote areas and help to establish better connect with
                the Universities. A single Examination will enable the candidates to cover a wide outreach and be part of
                the admission process to various Central Universities.` :
                  `Common University Entrance Test (CUET) is being introduced 
                  for admission into all UG/PG programmes in all Central Universities
                  for academic session  under the Ministry of Education (MoE).It was
                  introduced for the first time in 2022. A single examination will 
                  enable the candidates to cover a wide outreach & be part of the admission
                  process to various Central Universities & participating Universities of India.`}
              </p>
            </div>
            <div className="textcontent">
              <h4>Eligibility</h4>
              <p style={{ textAlign: "justify" }}>
                {type == "PG" ? `For appearing in the CUET (PG) 2024, there is no age limit for the candidates.
                The candidates who have passed the bachelor degree/ equivalent examination or
                appearing in 2024 irrespective of their age can appear in CUET (PG) 2024 examination.
                However, the candidates will be required to fulfil the age criteria of the
                University in which they are desirous of taking admission.` :
                  `For appearing in CUET (UG) there is no age limit for the candidates. The candidates who have passed the className XII/ equivalent examination or are appearing, irrespective of their age can appear in CUET (UG) examination. However, the candidates will be required to fulfil the age criteria (if any) of the University (ies) in which they are desirous of taking admission.`}
              </p>
            </div>

            {type == "PG" ?
              (<div className="textcontent">
                <h4>Mode Of Exam</h4>
                <p style={{ textAlign: "justify" }}>
                  <strong>CUET (PG) 2024 will be conducted in Computer Based Test (CBT) mode only.</strong>
                </p>
              </div>) : (
                <div className="textcontent">
                  <h4>Mode Of Exam</h4>
                  <p style={{ textAlign: "justify" }}>
                    <strong>Hybrid mode (Pen & Paper + CBT)</strong>
                  </p>
                  <p>Note: It will depend on the candidate count after the registration is complete.</p>
                </div>
              )}



            {type == "PG" ?
              (
                <div className="textcontent">
                  <h4>Medium of Question Papers</h4>
                  <p style={{ textAlign: "justify" }}>
                    The medium of Question Paper for CUET (PG) 2024 will be English and Hindi (Bilingual) except for languages, M.Tech Higher Sciences and Acharya papers (except Hindu studies, Baudha Darshan and Indian Knowledge System).
                  </p>
                </div>
              ) : (
                <div className="textcontent">
                  <h4>Medium of Examination</h4>
                  <p style={{ textAlign: "justify" }}>
                    The tests (other than "language" test) are offered in 13 languages i.e, Assamese,
                    Bengali, English, Gujarati,Hindi,Kannada,Malayalam,Marathi,Odiya,Punjabi,Tamil,
                    Telegu & Urdu. A candidate is required to opt for one of the specified languages as
                    the medium of the paper, as per desired University's eligibility criteria while applying.
                    Medium is not the same as the 'language' opted as the component of the test. 'Language'
                    test is for assessing the proficiency/skills of the candidate in the language opted by
                    him/her & the question paper in respect of the same will be available to the candidate in the medium opted by him/her only. The question paper of the 'language' test will not bilingual.
                  </p>
                </div>
              )}
            {type == "PG" ? <></> : (
              <div className="textcontent">
                <h4>Choice of Test Paper</h4>
                <p style={{ textAlign: "justify" }}>
                  Maximum 06 Test papers (04 or 05 domain subjects including General Test and 01 or 02 languages).
                </p>
                <h4>Medium of Qusetion Paper</h4>
                <p style={{ textAlign: "justify" }}>
                  The tests (other than "language" test) are offered in 13 languages .
                  <ul>
                    <li>Assamese</li>
                    <li>Bengali</li>
                    <li>English</li>
                    <li>Gujarati</li>
                    <li>Hindi</li>
                    <li>Kannada</li>
                    <li>Malayalam</li>
                    <li>Marathi</li>
                    <li>Odia</li>
                    <li>Punjabi</li>
                    <li>Tamil</li>
                    <li>Telegu</li>
                    <li>Urdu</li>
                  </ul>
                  A candidate is required to opt for one of the specified languages as the medium of the paper, as per desired University's eligibility criteria while applying.
                </p>
              </div>
            )
            }

            <div
              className="modeofexamctn"
              style={{
                padding: "1rem 2rem",
                width: "100%",
                background: "#71269C44",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4>{type == "PG" ? "Marking Scheme" : "Marking Scheme"}</h4>
              <div
                className="lictn"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {type == "PG" ? (<ul>
                  <li> - Each question carries 04 (four) marks.</li>
                  <li> - For each correct response, candidate will get 04 (four) marks.</li>
                  <li> - For each incorrect response, 01(one) mark will be deducted from the total score.</li>
                  <li> - Un-answered/un-attempted response will be given no marks.</li>
                </ul>) : (
                  <ul>
                    <li> - For each correct answer, five (5) marks will be rewarded and for each wrong answer -1 will be deducted. There is no mark for unattempt questions.</li>
                  </ul>
                )
                }
              </div>
            </div>


            {type == "PG" ? (<div
              className="modeofexamctn"
              style={{
                padding: "1rem 2rem",
                width: "100%",
                background: "#71269C44",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4>Syllabus of CUET (PG) 2024</h4>
              <div
                className="lictn"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <ul>
                  <li> - Entrance syllabus for question papers would be available in the official website <a href="https://pgcuet.samarth.ac.in"><span style={{ color: "#71269C" }}>https://pgcuet.samarth.ac.in</span></a> </li>
                </ul>
              </div>
            </div>) : (
              <div
                className="modeofexamctn"
                style={{
                  padding: "1rem 2rem",
                  width: "100%",
                  background: "#71269C44",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4>Syllabus of CUET (UG) 2024</h4>
                <div
                  className="lictn"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <ul>
                    <li> - The syllabus for subjects is available on the NTA website <a href="https://exams.nta.ac.in/CUET-UG/"><span style={{ color: "#71269C" }}> https://exams.nta.ac.in/CUET-UG/.</span> </a></li>
                  </ul>
                </div>
              </div>
            )}
            {type == "PG" ? (<div className="table-responsive-sm">
              <h4>Schedule of Examination</h4>
              <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                <tbody>
                  <tr>
                    <td>Dates of Examination </td>
                    <td colSpan="3">11 March 2024 to 28 March 2024</td>
                  </tr>
                  <tr>
                    <td>Mode of Examination </td>
                    <td colSpan="3"> Computer Based Test (CBT) mode only</td>
                  </tr>
                  <tr>
                    <th scope="col">Shift</th>
                    <th scope="col">Shift-1</th>
                    <th scope="col">Shift-2</th>
                    <th scope="col">Shift-3</th>
                  </tr>
                  <tr>
                    <td>Timing of Examination</td>
                    <td>9.00 a.m to 10.45 a.m</td>
                    <td>12.45 p.m to 2.30 p.m</td>
                    <td>4.30 p.m to 6.15 p.m</td>
                  </tr>
                  <tr>
                    <td>Duration of Examination </td>
                    <td>1 Hour and 45 minutes (105 Minutes) </td>
                    <td>1 Hour and 45 minutes (105 Minutes) </td>
                    <td>1 Hour and 45 minutes  (105 Minutes) </td>
                  </tr>
                  <tr>
                    <td>Total number of Questions </td>
                    <td colSpan="3">The question paper will have 75 questions. </td>
                  </tr>
                  <tr>
                    <td colSpan="4">Note:  Compensatory time for PwBD candidates of 20 minutes for every one-hour examination. </td>
                  </tr>
                </tbody>
              </table>
            </div>) : (
              <div className="table-responsive-sm">
                <h4>Schedule of Examination</h4>
                <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                  <tbody>
                    <tr>
                      <td>Dates of Examination </td>
                      <td>Between 15 May 2024 & 31 May 2024 (Dates may vary
                        depending on the National Election Schedule)</td>
                    </tr>
                    <tr>
                      <td>Pattern of Question Paper </td>
                      <td>Objective type Multiple Choice Questions (MCQs)</td>
                    </tr>
                    <tr>
                      <td>Total number of Questions </td>
                      <td>40 out of 50 questions for all test papers & 50 out of 60
                        questions for General Test</td>
                    </tr>
                    <tr>
                      <td>Duration of Examination </td>
                      <td>45 minutes for all test papers except
                        Mathematics/Applied
                        Mathematics,Accountancy,Physics,Chemistry,Economics
                        ,Computer Science/Informatics Practices,& General Test
                        which would be 60 minutes.</td>
                    </tr>
                    <tr>
                      <td>Slots</td>
                      <td>The examination will be conducted on multiple days in two
                        or three shifts per day, depending on the number of
                        candidates & their combinations.</td>
                    </tr>
                    <tr>
                      <td colSpan="2">NOTE: Compensatory time for PwBD Candidates of 20 minutes for each hour examination will be
                        given.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {type == "PG" ? (<div className="textcontent">
              <h4>Structure of Question Papers for CUET(PG) 2024:
              </h4>
              <ul>
                <li>- Candidates can choose maximum of 4 (four) question paper codes.</li>
                <li>- There would be no General Test with each paper.</li>
                <li>- The candidate can choose General Test paper as a subject. The comprehension part of these papers would be English or Hindi as chosen by the candidate during registration.
                </li>
              </ul>
            </div>) : (
              // <div
              //   className="modeofexamctn"
              //   style={{
              //     padding: "1rem 2rem",
              //     width: "100%",
              //     background: "#71269C44",
              //     borderRadius: "4px",
              //     display: "flex",
              //     flexDirection: "column",
              //   }}
              // >
              //   <div className="d-flex align-items-center">
              //     <h4>Please click here to know more</h4>
              //     <a href={"../../../../public/assets/document/exampatternofCUET.docx"} target="_blank" download={"exampatternofCUET.docx"}>&nbsp; - Open Document</a>
              //   </div>

              // </div>
              <></>
            )}
          </div>
          <div style={{ maxWidth: "30%" }}>
            {type = "UG" ? <>
              <div className="blog-bg mb-3 p-3" style={{ height: "auto", borderRadius: "10px", width: "100%" }}>
                <p style={{ textAlign: "left" }}>Unlock boundless knowledge from the comfort of your home with our online classes subscription – where learning knows no limits!</p>
                <button type="button" className="sub_button1 btn mt-2 " onClick={handleClickSubscription}>
                  CUET Online Subscription
                </button>
              </div>
            </> : <>
              <div>
                {pack && pack.length !== 0 ? (
                  <Swiper
                    allowTouchMove={false}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    onSlideChange={() => {
                      setSlideIndex(slideIndex + 1);
                    }}
                    className="custom-slider"
                    id="course-xyz-page"
                  >
                    {pack?.map((item, index) => {
                      let domainCount1 = item.avlDomainSubjects?.filter(
                        (x) => x.specialInstruction === true
                      ).length;
                      let langCount1 = item.avlLangSubjects?.filter(
                        (x) => x.specialInstruction === true
                      ).length;
                      let selLang = item.avlLangSubjects?.filter(
                        (x) => x.selection === true
                      ).length;
                      let selDomain = item.avlDomainSubjects?.filter(
                        (x) => x.selection === true
                      ).length;

                      let domainSub1 = [];
                      item.avlDomainSubjects?.filter((x) => {
                        if (x.specialInstruction || x.selection) {
                          domainSub1.push(x.topicName);
                          return x.topicName;
                        }
                      });

                      return (
                        <SwiperSlide key={index}>
                          <SubscriptionCard
                            item={item}
                            domainCount1={domainCount1}
                            langCount1={langCount1}
                            selLang={selLang}
                            selDomain={selDomain}
                            domainSub1={domainSub1}
                            setPackTitle={setPackTitle}
                            setTitle={setTitle}
                            setPackInc={setPackInc}
                            setPage={setPage}
                            setBoolaen={setBoolaen}
                            submitModal={submitModal}
                            setCheckout={setCheckout}
                            setCouponDetails={setCouponDetails}
                            setShow={setShow}
                            closeModal={closeModal}
                            pack={pack}
                            setPackTemp={setPackTemp}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  <div className="fallback-message-subscriptions-container">
                    <p>{fallbackMessage}</p>
                    {/* <p>Test From Ravi Sharma</p> */}
                  </div>
                )}
              </div>
            </>}
            <div>
              <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
              <ul style={{ fontSize: "14px" }}>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice paper</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Mock exam(replica of NTA interface)</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live classes by experts</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Study materials & previous year’s papers pdf</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Mock registration process</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Delhi University form fill - up Process</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live Quiz</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Self - performance analysis</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Individual Ranking</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Doubt resolution</span></small></strong></li>
              </ul>
            </div>
            <hr className="bg-secondary" />
            <div>
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaRegStar style={{ color: "#FFC107" }} />
              <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
            </div>
          </div>
        </div >)
      }
    </div >
  );
};

export default CUETCourse;
