// testing Local build 
// export default function baseUrl() {
// 	const url = "http://68.178.172.171:8282/besstMainApi";
// 	return url;
// }

export default function baseUrl() {
	const url = "/besstMainApi";
	return url;
}

// UAT build
// export default function baseUrl() {
// 	const url = "https://besst.co.in/besstMainApi";
// 	return url;
// }

// prod build
/* export default function baseUrl() {
	const url = "https://besst.in/besstMainApi";
	return url;
} */

// export default function baseUrl() {
// 	const url = "";
// 	return url;
// }

