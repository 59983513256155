import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigation } from "swiper";
import Card from "./Card.jsx";
import { useState, useEffect } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SubscriptionCard from "../../Payment/SubscriptionCard.jsx";
import Cookies from "js-cookie";
import baseUrl from "../../baseUrl.js";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";

const Class12 = () => {
  const [pack, setPack] = useState([]);
  const [title, setTitle] = useState("");
  const [packInc, setPackInc] = useState([]);
  const [packTitle, setPackTitle] = useState("");
  const [slideIndex, setSlideIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [boolaen, setBoolaen] = useState({
    alertTxt: false,
    modalValid: false,
    disDomain: false,
    showDomain: false,
    showLang: false,
    packAlrt: false,
  });
  const fallbackMessage = "Currently, No Subscription Pack is Available Please Check Later.";
  const [show, setShow] = useState(false);
  const [packTemp, setPackTemp] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [checkout, setCheckout] = useState({});
  const [selectedCourse, setSelectedCourse] = useState("1");
  const submitModal = (obj, checkVal, tempArr) => {
    tempArr = tempArr ? tempArr : packTemp;
    let countspecialInstruction = tempArr[0][obj].filter(
      (sub) => sub.specialInstruction === true
    ).length;
    if (countspecialInstruction >= tempArr[0][checkVal]) {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: false,
        modalValid: true,
      }));
    } else {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: true,
        modalValid: false,
      }));
    }
  };
  const breakpoints = {
    375: {
      slidesPerView: 1,
    },
    790: {
      slidesPerView: 1,
    },
    1150: {
      slidesPerView: 1,
    },
    1550: {
      slidesPerView: 1,
    },
    1900: {
      slidesPerView: 1,
    },
  };
  useEffect(() => {
    handleSubscriptionChange(selectedCourse);
  }, [selectedCourse]);
  const handleSubscriptionChange = (id) => {
    fetch(`${baseUrl()}/df/getAllSubscriptionPacks/5`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
        Authorization: `${Cookies.get("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPack(data.Data);
        setIsPrimium((prev) => ({
          ...prev,
          msg: data.message,
          status: data.status,
        }));
        // const payBtn = document.querySelector(".payBtn");
        // payBtn.style.pointerEvents = "none";
        // payBtn.innerText = "Free";
      })
      .catch((e) => console.log("subscription 01 CUET", e));
  };
  const closeModal = () => {
    setPackInc([]);
    setBoolaen((prev) => ({
      ...prev,
      showDomain: false,
      showLang: false,
      modalValid: false,
    }));
  };
  const [isPrimium, setIsPrimium] = useState({ msg: "", status: 400 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [filterToggle, setFilterToggle] = useState(false);
  const isSmallScreen = window.innerWidth <= 500;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={isSmallScreen ? "" : "cuetcontainer"} style={{ overflowX: "hidden" }}>
      <div className="cuethead">
        <h1>NCERT CLASS 12 SOLUTION</h1>
        <p>HOME/NCERT CLASS 12 SOLUTION</p>
      </div>
      {isSmallScreen ? (<Row>
        <Col md={6} sm={12} xs={12} className="faq_lay2 p-0">
          <div className="course_details_ctn">
            <div className="cuetctn">
              <h1 className="mb-2">NCERT CLASS 12 SOLUTION</h1>
              <p style={{ textAlign: "justify" }}>
                <strong>What is NCERT</strong>
                <br></br>The National Council of Educational Research and Training
                (NCERT) is a government organisation set up in 1961. Its main
                aim is to assist and advise the Central and state governments
                for qualitative improvement in school education.
              </p>
            </div>
            <div
              className="createdby"
            // style={{
            //   width: "100%",
            //   display: "flex",
            //   justifyContent: "space-between",
            // }}
            >
              {/* <div className="creatorctn" style={{ display: "flex" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/cuetimg/created by.PNG`}
                  alt="creators"
                  style={{ height: "60px" }}
                />
                <span>
                  <div>Created by</div>
                  <div>
                    <b>Dianne Russel • Kristen Watson</b>
                  </div>
                </span>
              </div> */}
              {/* <div
                className="rating"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  padding: "20px 0px"
                }}
              >
                <FaRegStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <span style={{ marginLeft: "0.5rem" }}>Rating: 4.5</span>
              </div> */}
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/cuetimg/987883-results.jpg`}
              className="courseimg"
              alt="CUET"
            />
            <div className="textcontent p-4">
              <h4>Major Objectives of NCERT</h4>

              <ul>
                <li style={{ textAlign: "justify" }}>
                  1.)Prepare and produce model textbooks, supplementary
                  materials, journals etc.
                </li>
                <li style={{ textAlign: "justify" }}>2.)Organise pre-service and in-service training of teachers.</li>
                <li style={{ textAlign: "justify" }}>
                  3.)Develop and disseminate innovative educational
                  techniques and practices.
                </li>
              </ul>
            </div>
            <div className="textcontent p-4">
              <h4>Class 12 NCERT:</h4>
              <p style={{ textAlign: "justify" }}>
                The National Council of Educational and Research Training
                publishes Class 12 books under the guidance of CBSE
                (Central Board of Secondary Education). All the questions that
                are asked in CBSE Board exams are completely based on
                CBSE NCERT books. If students are clear with questions and
                answers present in NCERT books for class 12 then they can
                not only score good marks in the Boards but also in the various
                competitive exams like CUET etc.
              </p>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  1) Most of the CBSE-affiliated schools follow the NCERT textbooks.
                </li>
                <li style={{ textAlign: "justify" }}>
                  2) Several state boards follow the NCERT textbooks like the
                  state boards of Delhi, Karnataka, Mizoram, and Tripura to
                  name a few.
                </li>
                <li style={{ textAlign: "justify" }}>
                  3) Assam Higher Secondary Education Council (AHSEC), a
                  state-level Board of Assam also follows books that are
                  prepared and published by NCERT.
                </li>
              </ul>
            </div>

            <div className="textcontent">
              <h4>What we are providing ? </h4>
              <p style={{ textAlign: "justify" }}>
                We provide NCERT solutions for class 12. Questions and
                Answers are given in such a manner that students can easily
                go through them during the time of their examinations.
              </p>
            </div>
            <div>
              <strong>Important Link :</strong>
              <a href="https://ncert.nic.in" target="_blank" rel="noopener noreferrer">
                <span style={{ color: "#7B1FA2" }}>Click Here</span>
              </a>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12} className="faq_lay2 pt-4">
          <div>
            <div>
              {pack && pack.length !== 0 ? (
                <Swiper
                  allowTouchMove={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  navigation={true}
                  modules={[Navigation]}
                  onSlideChange={() => {
                    setSlideIndex(slideIndex + 1);
                  }}
                  className="custom-slider"
                  id="course-xyz-page"
                >
                  {pack?.map((item, index) => {
                    let domainCount1 = item.avlDomainSubjects?.filter(
                      (x) => x.specialInstruction === true
                    ).length;
                    let langCount1 = item.avlLangSubjects?.filter(
                      (x) => x.specialInstruction === true
                    ).length;
                    let selLang = item.avlLangSubjects?.filter(
                      (x) => x.selection === true
                    ).length;
                    let selDomain = item.avlDomainSubjects?.filter(
                      (x) => x.selection === true
                    ).length;

                    let domainSub1 = [];
                    item.avlDomainSubjects?.filter((x) => {
                      if (x.specialInstruction || x.selection) {
                        domainSub1.push(x.topicName);
                        return x.topicName;
                      }
                    });

                    return (
                      <SwiperSlide key={index}>
                        <SubscriptionCard
                          item={item}
                          domainCount1={domainCount1}
                          langCount1={langCount1}
                          selLang={selLang}
                          selDomain={selDomain}
                          domainSub1={domainSub1}
                          setPackTitle={setPackTitle}
                          setTitle={setTitle}
                          setPackInc={setPackInc}
                          setPage={setPage}
                          setBoolaen={setBoolaen}
                          submitModal={submitModal}
                          setCheckout={setCheckout}
                          setCouponDetails={setCouponDetails}
                          setShow={setShow}
                          closeModal={closeModal}
                          pack={pack}
                          setPackTemp={setPackTemp}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                //<div className="fallback-message-subscriptions-container text-left" >
                //   <p>{fallbackMessage}</p>
                //</div>
                <div className="text-left mt-5">
                  <h5 className="mb-4" style={{ width: "200px" }}>{fallbackMessage}</h5>
                </div>
              )}
            </div>
            <div className="p-4">
              <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
              <ul style={{ fontSize: "12px" }}>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice papers</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Performance Analysis</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Individual Ranking</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live Quiz</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Study materials pdf</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Previous year’s question papers pdf</span></small></strong></li>
              </ul>
            </div>
            <hr className="bg-secondary " />
            <div className="p-4">
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaRegStar style={{ color: "#FFC107" }} />
              <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
            </div>
          </div>
        </Col>
      </Row>) :
        (<div className="cuetcontent">
          <div className="course_details_ctn">
            <div className="cuetctn">
              <h1 className="mb-2">NCERT CLASS 12 SOLUTION</h1>
              <p style={{ textAlign: "justify" }}>
                <strong>What is NCERT</strong><br></br>The National Council of Educational Research and Training
                (NCERT) is a government organisation set up in 1961. Its main
                aim is to assist and advise the Central and state governments
                for qualitative improvement in school education.
              </p>
            </div>
            <div
              className="createdby"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <div className="creatorctn" style={{ display: "flex" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/cuetimg/created by.PNG`}
                  alt="creators"
                  style={{ height: "60px" }}
                />
                <span>
                  <div>Created by</div>
                  <div>
                    <b>Dianne Russel • Kristen Watson</b>
                  </div>
                </span>
              </div> */}
              {/* <div
                className="rating"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaRegStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <span style={{ marginLeft: "0.5rem" }}>Rating: 4.5</span>
              </div> */}
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/cuetimg/987883-results.jpg`}
              className="courseimg"
              alt="CUET"
            />
            <div className="textcontent p-4">
              <h4>Major Objectives of NCERT</h4>

              <ul>
                <li style={{ textAlign: "justify" }}>
                  1.)Prepare and produce model textbooks, supplementary
                  materials, journals etc.
                </li>
                <li style={{ textAlign: "justify" }}>2.)Organise pre-service and in-service training of teachers.</li>
                <li style={{ textAlign: "justify" }}>
                  3.)Develop and disseminate innovative educational
                  techniques and practices.
                </li>
              </ul>
            </div>
            <div className="textcontent p-4">
              <h4>Class 12 NCERT:</h4>
              <p style={{ textAlign: "justify" }}>
                The National Council of Educational and Research Training
                publishes Class 12 books under the guidance of CBSE
                (Central Board of Secondary Education). All the questions that
                are asked in CBSE Board exams are completely based on
                CBSE NCERT books. If students are clear with questions and
                answers present in NCERT books for class 12 then they can
                not only score good marks in the Boards but also in the various
                competitive exams like CUET etc.
              </p>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  1) Most of the CBSE-affiliated schools follow the NCERT textbooks.
                </li>
                <li style={{ textAlign: "justify" }}>
                  2) Several state boards follow the NCERT textbooks like the
                  state boards of Delhi, Karnataka, Mizoram, and Tripura to
                  name a few.{" "}
                </li>
                <li style={{ textAlign: "justify" }}>
                  3) Assam Higher Secondary Education Council (AHSEC), a
                  state-level Board of Assam also follows books that are
                  prepared and published by NCERT.
                </li>
              </ul>
            </div>

            <div className="textcontent">
              <h4>What we are providing ? </h4>
              <p style={{ textAlign: "justify" }}>
                We provide NCERT solutions for class 12. Questions and
                Answers are given in such a manner that students can easily
                go through them during the time of their examinations.
              </p>
            </div>
            <div>
              <strong>Important Link :</strong>
              <a href="https://ncert.nic.in" target="_blank" rel="noopener noreferrer"> <span style={{ color: "#7B1FA2" }}>Click Here</span></a>
            </div>
          </div>
          <div style={{ maxWidth: "30%" }}>
            <div>
              {pack && pack.length !== 0 ? (
                <Swiper
                  allowTouchMove={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  navigation={true}
                  modules={[Navigation]}
                  onSlideChange={() => {
                    setSlideIndex(slideIndex + 1);
                  }}
                  className="custom-slider"
                  id="course-xyz-page"
                >
                  {pack?.map((item, index) => {
                    let domainCount1 = item.avlDomainSubjects?.filter(
                      (x) => x.specialInstruction === true
                    ).length;
                    let langCount1 = item.avlLangSubjects?.filter(
                      (x) => x.specialInstruction === true
                    ).length;
                    let selLang = item.avlLangSubjects?.filter(
                      (x) => x.selection === true
                    ).length;
                    let selDomain = item.avlDomainSubjects?.filter(
                      (x) => x.selection === true
                    ).length;

                    let domainSub1 = [];
                    item.avlDomainSubjects?.filter((x) => {
                      if (x.specialInstruction || x.selection) {
                        domainSub1.push(x.topicName);
                        return x.topicName;
                      }
                    });

                    return (
                      <SwiperSlide key={index}>
                        <SubscriptionCard
                          item={item}
                          domainCount1={domainCount1}
                          langCount1={langCount1}
                          selLang={selLang}
                          selDomain={selDomain}
                          domainSub1={domainSub1}
                          setPackTitle={setPackTitle}
                          setTitle={setTitle}
                          setPackInc={setPackInc}
                          setPage={setPage}
                          setBoolaen={setBoolaen}
                          submitModal={submitModal}
                          setCheckout={setCheckout}
                          setCouponDetails={setCouponDetails}
                          setShow={setShow}
                          closeModal={closeModal}
                          pack={pack}
                          setPackTemp={setPackTemp}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <div className="text-left mt-5">
                  <h5 className="mb-4" >{fallbackMessage}</h5>
                </div>
              )}
            </div>
            <div>
              <h4 style={{ margin: "2px 0px 10px 0px", width: "200px" }}>This course included : </h4>
              <ul style={{ fontSize: "14px" }}>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice papers</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Performance Analysis</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Individual Ranking</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live Quiz</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Study materials pdf</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Previous year’s question papers pdf</span></small></strong></li>
              </ul>
            </div>
            <hr className="bg-secondary" />
            <div>
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaRegStar style={{ color: "#FFC107" }} />
              <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
            </div>
          </div>
        </div>)}
    </div>
  );
};

export default Class12;
