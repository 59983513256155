import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "./Card.jsx";
import { Navigation } from "swiper";
import { useState, useEffect } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import Maths from "../../../Assets/images/maths.png";
import Science from "../../../Assets/images/science.png";
import SocialScience from "../../../Assets/images/social-science.png";
import English from "../../../Assets/images/english.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SubscriptionCard from "../../Payment/SubscriptionCard.jsx";
import Cookies from "js-cookie";
import baseUrl from "../../baseUrl.js";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";

const Class10 = () => {
  const [pack, setPack] = useState([]);
  const [title, setTitle] = useState("");
  const [packInc, setPackInc] = useState([]);
  const [packTitle, setPackTitle] = useState("");
  const [slideIndex, setSlideIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [boolaen, setBoolaen] = useState({
    alertTxt: false,
    modalValid: false,
    disDomain: false,
    showDomain: false,
    showLang: false,
    packAlrt: false,
  });
  const fallbackMessage = "Currently, No Subscription Pack is Available Please Check Later";
  const [show, setShow] = useState(false);
  const [packTemp, setPackTemp] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [checkout, setCheckout] = useState({});
  const [selectedCourse, setSelectedCourse] = useState("1");
  const submitModal = (obj, checkVal, tempArr) => {
    tempArr = tempArr ? tempArr : packTemp;
    let countspecialInstruction = tempArr[0][obj].filter(
      (sub) => sub.specialInstruction === true
    ).length;
    if (countspecialInstruction >= tempArr[0][checkVal]) {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: false,
        modalValid: true,
      }));
    } else {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: true,
        modalValid: false,
      }));
    }
  };
  const breakpoints = {
    375: {
      slidesPerView: 1,
    },
    790: {
      slidesPerView: 1,
    },
    1150: {
      slidesPerView: 1,
    },
    1550: {
      slidesPerView: 1,
    },
    1900: {
      slidesPerView: 1,
    },
  };

  const isSmallScreen = window.innerWidth <= 500;

  useEffect(() => {
    handleSubscriptionChange(selectedCourse);
  }, [selectedCourse]);
  const handleSubscriptionChange = (id) => {
    fetch(`${baseUrl()}/df/getAllSubscriptionPacks/6`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
        Authorization: `${Cookies.get("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPack(data.Data);
        setIsPrimium((prev) => ({
          ...prev,
          msg: data.message,
          status: data.status,
        }));
        // const payBtn = document.querySelector(".payBtn");
        // payBtn.style.pointerEvents = "none";
        // payBtn.innerText = "Free";
      })
      .catch((e) => console.log("subscription 01 CUET", e));
  };
  const closeModal = () => {
    setPackInc([]);
    setBoolaen((prev) => ({
      ...prev,
      showDomain: false,
      showLang: false,
      modalValid: false,
    }));
  };
  const [isPrimium, setIsPrimium] = useState({ msg: "", status: 400 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [filterToggle, setFilterToggle] = useState(false);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="cuetcontainer" style={{ overflowX: "hidden" }}>
      <div className="cuethead">
        <h1>Class 10 (SEBA)</h1>
        <p>Home / Class 10 (SEBA)</p>
      </div>
      {isSmallScreen ?
        (<Row>
          <Col md={6} sm={12} xs={12} className="faq_lay2 p-0">
            <div className="course_details_ctn pl-0">
              <div className="cuetctn">
                <h1>Class 10 (SEBA)</h1>
                <p style={{ textAlign: "justify" }}>
                  The Board of secondary education, Assam is commonly known as SEBA.
                  It is the prime education regulatory board under the jurisdiction
                  of the Ministry of Education, Assam. It was established in 1962.
                  Its headquarter is in Guwahati. This board conducts the high
                  school leaving certificate for the students of 10th standard.
                </p>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/cuetimg/987883-results.jpg`}
                className="courseimg"
                alt="CUET"
              />
              {/* <div className="textcontent">
              <h4>Major Objectives of NCERT</h4>
  
              <ul>
                <li style={{ textAlign: "justify" }}>
                  - Prepare and produce model textbooks, supplementary materials,
                  journals etc.
                </li>
                <li style={{ textAlign: "justify" }}>- Organise pre-service and in-service training of teachers.</li>
                <li style={{ textAlign: "justify" }}>
                  - Develop and disseminate innovative educational techniques and
                  practices.
                </li>
              </ul>
            </div> */}
              {/* <div className="textcontent">
              <h4>What are we providing</h4>
              <p style={{ textAlign: "justify" }}>
                We provide NCERT solutions for className 12. Questions and answers are
                given in such a manner that students can easily go through them
                during the time of their examinations.
              </p>
            </div> */}
              <div className="textcontent">
                <h4>
                  SEBA announced on{" "}
                  <span style={{ color: "#7B1FA2" }}>August 11, 2022</span>
                </h4>
                <p style={{ textAlign: "justify" }}>
                  To reform the exam pattern for the high school leaving certificate
                  (Class 10) exam. This change has been introduced by the new
                  education policy (NEP)2020. As per the announcement, the exam
                  pattern for <span style={{ color: "#7B1FA2" }}>4 subjects</span>{" "}
                  will change. These subjects are:
                </p>
                <div className="subjects-container">
                  <div className="subject-card">
                    <img src={SocialScience} alt="subjects" />
                    <h6 className="subject-name">1.)Social Science</h6>
                  </div>
                  <div className="subject-card">
                    <img src={Maths} alt="subjects" />
                    <h6 className="subject-name">2.)General Maths</h6>
                  </div>
                  <div className="subject-card">
                    <img src={Science} alt="subjects" />
                    <h6 className="subject-name">3.)General Science</h6>
                  </div>
                  <div className="subject-card">
                    <img src={English} alt="subjects" />
                    <h6 className="subject-name">4.)English</h6>
                  </div>
                </div>
              </div>
              <div className="textcontent">
                <h5>This new exam pattern is implemented from 2023 HSLC.</h5>
                <ul>
                  <li>
                    <span style={{ color: "#7B1FA2" }}>Mode of Exam:</span> Pen and
                    Paper
                  </li>
                  <li>
                    <span style={{ color: "#7B1FA2" }}>Official Language : </span>{" "}
                    Assamese, English and Hindi
                  </li>
                </ul>
              </div>
              <div className="textcontent">
                <h5>
                  The 100-point{" "}
                  <span style={{ color: "#7B1FA2" }}>Question Papers</span> will
                  include <span style={{ color: "#7B1FA2" }}>45-points</span>{" "}
                  objective-type questions in{" "}
                  <span style={{ color: "#7B1FA2" }}>4 subjects</span>
                </h5>
                <ul style={{ display: "flex", gap: "2em" }}>
                  <li>( English </li>
                  <li>General Science </li>
                  <li>Social Science </li>
                  <li>Maths )</li>
                </ul>
                <ul style={{ listStyle: "square" }}>
                  <li>
                    50% of the questions (i.e. 45 questions)will be objective-type
                    questions
                  </li>
                  <li>
                    With the remaining 50% consisting of an internal assessment of
                    10 marks and mix of objective type question.
                  </li>
                </ul>
              </div>

              <div>
                <strong>Official Website:</strong>
                <a href="https://site.sebaonline.org" className="website-link" target="_blank" rel="noopener noreferrer"> Visit the link</a>
              </div>
              <div className="textcontent p-4">
                <h4>The content we are providing </h4>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    1)MCQs
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    2)Practice papers
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    3)Mock papers
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    4)Subjective questions and answers
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    5)Handwritten notes of rank holders
                  </li>

                </ul>
              </div>


            </div>
          </Col>
          <Col md={6} sm={12} xs={12} className="faq_lay2 pt-4">
            <div>
              <div>
                {pack && pack.length !== 0 ? (
                  <Swiper
                    allowTouchMove={false}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    onSlideChange={() => {
                      setSlideIndex(slideIndex + 1);
                    }}
                    className="custom-slider"
                    id="course-xyz-page"
                  >
                    {pack?.map((item, index) => {
                      let domainCount1 = item.avlDomainSubjects?.filter(
                        (x) => x.specialInstruction === true
                      ).length;
                      let langCount1 = item.avlLangSubjects?.filter(
                        (x) => x.specialInstruction === true
                      ).length;
                      let selLang = item.avlLangSubjects?.filter(
                        (x) => x.selection === true
                      ).length;
                      let selDomain = item.avlDomainSubjects?.filter(
                        (x) => x.selection === true
                      ).length;

                      let domainSub1 = [];
                      item.avlDomainSubjects?.filter((x) => {
                        if (x.specialInstruction || x.selection) {
                          domainSub1.push(x.topicName);
                          return x.topicName;
                        }
                      });

                      return (
                        <SwiperSlide key={index}>
                          <SubscriptionCard
                            item={item}
                            domainCount1={domainCount1}
                            langCount1={langCount1}
                            selLang={selLang}
                            selDomain={selDomain}
                            domainSub1={domainSub1}
                            setPackTitle={setPackTitle}
                            setTitle={setTitle}
                            setPackInc={setPackInc}
                            setPage={setPage}
                            setBoolaen={setBoolaen}
                            submitModal={submitModal}
                            setCheckout={setCheckout}
                            setCouponDetails={setCouponDetails}
                            setShow={setShow}
                            closeModal={closeModal}
                            pack={pack}
                            setPackTemp={setPackTemp}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  //<div className="fallback-message-subscriptions-container text-left" >
                  //   <p>{fallbackMessage}</p>
                  //</div>
                  <div className="text-left mt-5">
                    <h5 className="mb-4" style={{ width: "200px" }}>{fallbackMessage}</h5>
                  </div>
                )}
              </div>
              <div className="p-4">
                <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
                <ul style={{ fontSize: "12px" }}>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice papers</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Performance Analysis</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Individual Ranking</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live Quiz</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Study materials pdf</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Previous year’s question papers pdf</span></small></strong></li>
                </ul>
              </div>
              <hr className="bg-secondary " />
              <div className="p-4">
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaRegStar style={{ color: "#FFC107" }} />
                <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
              </div>
            </div>
          </Col>
        </Row>
        ) :
        (
          <div className="cuetcontent">
            <div className="course_details_ctn">
              <div className="cuetctn">
                <h1>Class 10 (SEBA)</h1>
                <p style={{ textAlign: "justify" }}>
                  The Board of secondary education, Assam is commonly known as SEBA.
                  It is the prime education regulatory board under the jurisdiction
                  of the Ministry of Education, Assam. It was established in 1962.
                  Its headquarter is in Guwahati. This board conducts the high
                  school leaving certificate for the students of 10th standard.
                </p>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/cuetimg/987883-results.jpg`}
                className="courseimg"
                alt="CUET"
              />
              {/* <div className="textcontent">
              <h4>Major Objectives of NCERT</h4>
  
              <ul>
                <li style={{ textAlign: "justify" }}>
                  - Prepare and produce model textbooks, supplementary materials,
                  journals etc.
                </li>
                <li style={{ textAlign: "justify" }}>- Organise pre-service and in-service training of teachers.</li>
                <li style={{ textAlign: "justify" }}>
                  - Develop and disseminate innovative educational techniques and
                  practices.
                </li>
              </ul>
            </div> */}
              {/* <div className="textcontent">
              <h4>What are we providing</h4>
              <p style={{ textAlign: "justify" }}>
                We provide NCERT solutions for className 12. Questions and answers are
                given in such a manner that students can easily go through them
                during the time of their examinations.
              </p>
            </div> */}
              <div className="textcontent">
                <h4>
                  SEBA announced on{" "}
                  <span style={{ color: "#7B1FA2" }}>August 11, 2022</span>
                </h4>
                <p style={{ textAlign: "justify" }}>
                  To reform the exam pattern for the high school leaving certificate
                  (Class 10) exam. This change has been introduced by the new
                  education policy (NEP)2020. As per the announcement, the exam
                  pattern for <span style={{ color: "#7B1FA2" }}>4 subjects</span>{" "}
                  will change. These subjects are:
                </p>
                <div className="subjects-container">
                  <div className="subject-card">
                    <img src={SocialScience} alt="subjects" />
                    <h6 className="subject-name">1.)Social Science</h6>
                  </div>
                  <div className="subject-card">
                    <img src={Maths} alt="subjects" />
                    <h6 className="subject-name">2.)General Maths</h6>
                  </div>
                  <div className="subject-card">
                    <img src={Science} alt="subjects" />
                    <h6 className="subject-name">3.)General Science</h6>
                  </div>
                  <div className="subject-card">
                    <img src={English} alt="subjects" />
                    <h6 className="subject-name">4.)English</h6>
                  </div>
                </div>
              </div>
              <div className="textcontent">
                <h5>This new exam pattern is implemented from 2023 HSLC.</h5>
                <ul>
                  <li>
                    <span style={{ color: "#7B1FA2" }}>Mode of Exam:</span> Pen and
                    Paper
                  </li>
                  <li>
                    <span style={{ color: "#7B1FA2" }}>Official Language : </span>{" "}
                    Assamese, English and Hindi
                  </li>
                </ul>
              </div>
              <div className="textcontent">
                <h5>
                  The 100-point{" "}
                  <span style={{ color: "#7B1FA2" }}>Question Papers</span> will
                  include <span style={{ color: "#7B1FA2" }}>45-points</span>{" "}
                  objective-type questions in{" "}
                  <span style={{ color: "#7B1FA2" }}>4 subjects</span>
                </h5>
                <ul style={{ display: "flex", gap: "2em" }}>
                  <li>( English </li>
                  <li>General Science </li>
                  <li>Social Science </li>
                  <li>Maths )</li>
                </ul>
                <ul style={{ listStyle: "square" }}>
                  <li>
                    50% of the questions (i.e. 45 questions)will be objective-type
                    questions
                  </li>
                  <li>
                    With the remaining 50% consisting of an internal assessment of
                    10 marks and mix of objective type question.
                  </li>
                </ul>
              </div>

              <div>
                <strong>Official Website:</strong>
                <a href="https://site.sebaonline.org" className="website-link" target="_blank" rel="noopener noreferrer"> Visit the link</a>
              </div>
              <div className="textcontent p-4">
                <h4>The content we are providing </h4>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    1)MCQs
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    2)Practice papers
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    3)Mock papers
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    4)Subjective questions and answers
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    5)Handwritten notes of rank holders
                  </li>

                </ul>
              </div>


            </div>
            <div style={{ maxWidth: "30%" }}>
              <div>
                {pack && pack.length !== 0 ? (
                  <Swiper
                    allowTouchMove={false}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    onSlideChange={() => {
                      setSlideIndex(slideIndex + 1);
                    }}
                    className="custom-slider"
                    id="course-xyz-page"
                  >
                    {pack?.map((item, index) => {
                      let domainCount1 = item.avlDomainSubjects?.filter(
                        (x) => x.specialInstruction === true
                      ).length;
                      let langCount1 = item.avlLangSubjects?.filter(
                        (x) => x.specialInstruction === true
                      ).length;
                      let selLang = item.avlLangSubjects?.filter(
                        (x) => x.selection === true
                      ).length;
                      let selDomain = item.avlDomainSubjects?.filter(
                        (x) => x.selection === true
                      ).length;

                      let domainSub1 = [];
                      item.avlDomainSubjects?.filter((x) => {
                        if (x.specialInstruction || x.selection) {
                          domainSub1.push(x.topicName);
                          return x.topicName;
                        }
                      });

                      return (
                        <SwiperSlide key={index}>
                          <SubscriptionCard
                            item={item}
                            domainCount1={domainCount1}
                            langCount1={langCount1}
                            selLang={selLang}
                            selDomain={selDomain}
                            domainSub1={domainSub1}
                            setPackTitle={setPackTitle}
                            setTitle={setTitle}
                            setPackInc={setPackInc}
                            setPage={setPage}
                            setBoolaen={setBoolaen}
                            submitModal={submitModal}
                            setCheckout={setCheckout}
                            setCouponDetails={setCouponDetails}
                            setShow={setShow}
                            closeModal={closeModal}
                            pack={pack}
                            setPackTemp={setPackTemp}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  // <div className="fallback-message-subscriptions-container">
                  //   <p>{fallbackMessage}</p>
                  // </div>
                  <div className="text-left mt-5">
                    <h5 className="mb-4" style={{ width: "200px" }}>{fallbackMessage}</h5>
                  </div>
                )}
              </div>
              <div>
                <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
                <ul style={{ fontSize: "14px" }}>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice papers</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject / Chapter wise subjective questions and answers</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Hand written notes of previous rank holders pdf</span></small></strong></li>
                  <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Previous year’s question papers pdf</span></small></strong></li>
                </ul>
              </div>
              <hr className="bg-secondary" />
              <div>
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaStar style={{ color: "#FFC107" }} />
                <FaRegStar style={{ color: "#FFC107" }} />
                <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
              </div>
            </div>
          </div>
        )
      }
    </div >
  );
};

export default Class10;
