import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  faXTwitter,
  faYoutube,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppleImage from "../../../Assets/images/apple-store.png";
import PlayStore from "../../../Assets/images/play-store.png";
import QRCode from "../../../Assets/images/qr-code.jpeg";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function Footer() {
  const currentOrigin = window.location.origin;
  const currentLocation = `${currentOrigin}/`;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isSmallScreen = window.innerWidth <= 500;
  useEffect(() => {
    const checkLoginStatus = () => {
      // console.log("tokenValue", Cookies.get("token"));
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);

  const handleClick = () => {
    window.location.href = "/";
  }

  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
  const defaultMessage = process.env.REACT_APP_MESSAGE;
  return (
    <div style={{ display: "block", marginTop: isSmallScreen ? "150px" : "50px" }} fluid className="footer_bak">
      <div className="footer_con text-center">
        {isLoggedIn ? null : (<div id="footer-style" className="footer-row">
          <div>
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                paddingLeft: "0",
              }}
            >
              <h6
                style={{
                  marginLeft: "0",
                  textAlign: "left",
                  alignItems: "left",
                }}
                className="footer_heading text-uppercase fw-bold mb-4 "
              >
                Got Questions?
              </h6>
              {/* mobile */}
              <span className="bg_color">Mobile</span>
              <p>
                <a
                  href="tel:+918811963303"
                  className="bg_color "
                  style={{ textDecorationLine: "underline" }}
                >
                  +91 8811963303
                </a>
              </p>
              <p>
                <a
                  href="tel:+918811963303"
                  className="bg_color "
                  style={{ textDecorationLine: "underline" }}
                >
                  +91 8133836741
                </a>
              </p>
              {/* whatsapp */}
              <span className="bg_color">Whatsapp</span>
              {/* <p>
                <a
                  href={`https://wa.me/${phoneNumber}?text=${defaultMessage}`}
                  className="bg_color"
                  target="_blank"
                  style={{ textDecorationLine: "underline" }}
                >
                  +91 {phoneNumber}
                </a>
              </p> */}
              <p>
                <a
                  href={`https://wa.me/8133836741?text=${defaultMessage}`}
                  className="bg_color"
                  target="_blank"
                  style={{ textDecorationLine: "underline" }}
                >
                  +91 8133836741
                </a>
              </p>
              <span className="bg_color">Email</span>
              <p>
                <a
                  href="mailto:info@besst.in?subject=Request%20for%20Reconsideration%20of%20Salary%20Revision&body=Dear%20[Recipient's%20Name],%0A%0AI%20hope%20this%20email%20finds%20you%20well.%20I%20would%20like%20to%20express%20my%20gratitude%20for%20the%20recent%20salary%20revision%3B%20however%2C%20after%20careful%20consideration%2C%20I%20find%20myself%20compelled%20to%20discuss%20my%20concerns%20regarding%20the%20adjustment.%0A%0AI%20want%20to%20emphasize%20my%20dedication%20to%20the%20team%20and%20my%20enthusiasm%20for%20contributing%20to%20the%20continued%20success%20of%20TechAhead.%20The%20company%20has%20provided%20me%20with%20invaluable%20experiences%2C%20and%20I%20am%20committed%20to%20playing%20a%20significant%20role%20in%20its%20growth.%0A%0AHaving%20received%20offers%20from%20other%20firms%2C%20it%20became%20apparent%20that%20the%20recent%20salary%20revision%20falls%20short%20of%20my%20expectations.%20While%20I%20deeply%20appreciate%20the%20recognition%20of%20my%20contributions%2C%20the%20current%20adjustment%20doesn't%20align%20with%20the%20industry%20standards%20and%20the%20offers%20I've%20received%20from%20elsewhere.%0A%0AI%20believe%20in%20open%20communication%20and%20transparency%2C%20and%20it%20is%20with%20this%20spirit%20that%20I%20am%20reaching%20out%20to%20discuss%20the%20possibility%20of%20a%20reconsideration%20of%20my%20revised%20salary.%20I%20am%20more%20than%20willing%20to%20engage%20in%20a%20constructive%20dialogue%20to%20find%20a%20solution%20that%20aligns%20both%20with%20my%20expectations%20and%20the%20company's%20budgetary%20constraints.%0A%0AI%20look%20forward%20to%20discussing%20this%20matter%20further%20and%20appreciate%20your%20understanding%20and%20consideration.%0A%0AThank%20you%20for%20your%20time.%0A%0ABest%20regards%2C%0A%0A[Your%20Full%20Name]%0A[Your%20Position]%0A[Contact%20Information]"
                  className="bg_color"
                  target="_blank"
                  style={{ textDecorationLine: "underline" }}
                >
                  {" "}
                  info@besst.in{" "}
                </a>
              </p>
              <div sm={12} md={5} className="location bg_color mt-2">
                <FontAwesomeIcon icon={faLocationDot} /> <span className="">Head Office</span>
                <p style={{ width: "20vw", fontWeight: "300" }} >
                  Brahmaputra Exam Success Support Team Private Limited,&nbsp;37, 2nd
                  bye lane B.R.Mazumdar Path Baghorbori, Panjabari
                  Guwahati-781037 Assam
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                paddingLeft: "0",
              }}
            >
              <h6
                style={{
                  textAlign: "left",
                  alignItems: "left",
                  marginLeft: "0",
                }}
                className=" footer_heading  text-uppercase fw-bold mb-4"
              >
                About Us
              </h6>
              <p>
                <a href={`${currentLocation}about`} className="bg_color">
                  {" "}
                  About us
                </a>
              </p>
              <p>
                <a href={`/cookie-policy`} className="bg_color">
                  Cookie Policy
                </a>
              </p>
              <p>
                <a href={`${currentLocation}documents/privacy-policy`} className="bg_color">
                  Privacy Policy
                </a>
              </p>
              <p>
                <a href={`${currentLocation}documents/terms-and-conditions`} className="bg_color">
                  Terms of Service
                </a>
              </p>
              {/* <p>
                <a href={`${currentLocation}documents/privacy-policy`} className="bg_color">
                  Cookie Policy
                </a>
              </p> */}
              {/* <p>
                <a href="#!" className="bg_color">
                  Accessibility Statement
                </a>
              </p> */}
              <p>
                <a href={`${currentLocation}contact`} className="bg_color">
                  Write to Us?
                </a>
              </p>
              {/* <span className="mt-4 ">
                <Image src={`${process.env.PUBLIC_URL}/assets/Map.png`} className="qr h-25 w-25" alt="qr-code" />
              </span> */}
              <div sm={12} md={5} className="location bg_color mt-2">
                <FontAwesomeIcon icon={faLocationDot} /> <span className="">New Delhi Office</span>
                <p style={{ width: "20vw", fontWeight: "300" }} >
                  26A/UA Mezzanine Floor Main <br />
                  Banglow Road, Near hansraj hostel
                  <br />gate No. 5 , Kamla Nagar ,
                  <br /> Delhi-110007
                </p>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                marginLeft: "0",
              }}
            >
              <h6
                style={{
                  textAlign: "left",
                  alignItems: "left",
                  marginLeft: "0",
                }}
                className=" footer_heading text-uppercase fw-bold mb-4"
              >
                Courses
              </h6>
              {/* 
              <p>
                <a href={`${currentLocation}contact`} className="bg_color">
                  Contact
                </a>
              </p> */}
              <p>
                <a href="/cuetugcourse" className="bg_color">
                  {" "}
                  CUET(UG)
                </a>
              </p>
              <p>
                <a href="/cuetexamcourse" className="bg_color">
                  {" "}
                  CUET(PG)
                </a>
              </p>
              <p>
                <a href="/className12" className="bg_color">
                  {" "}
                  NCERT(12th)
                </a>
              </p>
              <p>
                <a href="/className10" className="bg_color">
                  {" "}
                  SEBA(10th)
                </a>
              </p>
              <p>
                <a className="bg_color">
                  {" "}
                  NTSE
                </a>
              </p>
              <div
                style={{
                  textAlign: "left",
                  alignItems: "left",
                  marginLeft: "0",
                }}
                className="footer-row"
              >
                <div sm={12} md={3}>
                  <div>
                    {/* <span className="bg_color">Follow Our Social</span> */}
                    <h6
                      style={{
                        textAlign: "left",
                        alignItems: "left",
                        marginLeft: "0",
                        marginTop: "20px"
                      }}
                      className=" footer_heading  text-uppercase fw-bold mb-4"
                    >
                      Other Pages
                    </h6>
                    <p>
                      <a href="/cuetugcourse" className="bg_color">
                        {" "}
                        Top Courses
                      </a>
                    </p>
                    <p>
                      <a href={`${currentLocation}success-stories`} className="bg_color">
                        Success Stories
                      </a>
                    </p>
                    <p>
                      <a href={`${currentLocation}current-affairs`} className="bg_color">
                        Current Affairs
                      </a>
                    </p>
                    <p>
                      <a href={`${currentLocation}blog`} className="bg_color">
                        Blogs & Articles
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                textAlign: "left",
                alignItems: "left",
                marginLeft: "0",
              }}
            >
              <h6
                style={{
                  textAlign: "left",
                  alignItems: "left",
                  marginLeft: "0",
                }}
                className=" footer_heading text-uppercase fw-bold mb-4"
              >
                Services
              </h6>
              <p>
                <a href="/online-clases" className="bg_color">
                  Online classNamees
                </a>
              </p>
              <p>
                <a href="/cuet-mock-test" className="bg_color">
                  Mock Test Series
                </a>
              </p>
              <p>
                <a href="/download-study-material" className="bg_color">
                  Downloadable Study Materials
                </a>
              </p>
              <p>
                <a className="bg_color" href="/previous-year-question-bank">
                  Previous Year Question Bank
                </a>
              </p>
              <p>
                <a href="/counseling-mock-registration" className="bg_color">
                  Counselling and Mock Registration for Admission in Central University
                </a>
              </p>
              <p>
                <a className="bg_color">
                  Complete Guidance for Admission Process of Delhi University
                </a>
              </p>
              <div sm={12} md={3} className="mt-3">
                <h3 className="bg_color text-white">Get Our Mobile App</h3>
                <div className="footer-row">
                  <div>
                    <div>
                      <Image
                        src={AppleImage}
                        className="app-download"
                        alt="apple-store"
                      />
                    </div>
                    <div>
                      <a href="https://play.google.com/store/apps/details?id=com.besst">
                        <Image
                          src={PlayStore}
                          className="app-download"
                          alt="apple-store"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="ml-4">
                    <Image src={QRCode} className="qr" alt="qr-code" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}

        <div>
          <div sm={12} md={12} className="py-3">
            <div className="text-center bg_color padding_tb_5_px">
              Copyright © 2024 BESST (Brahmaputra Exam Success Support Team Private Limited)
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Footer;
