import React, { useState } from "react";
import { Button, Row, Col, Card, Image, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaSearch,
  FaFilter,
  FaChevronDown,
  FaChevronRight,
} from "react-icons/fa";
import SliderIcon from "../../../Assets/images/slider.svg";
import CurrentImage from "../../../Assets/images/current-affairs-image.png";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { padding } from "aes-js";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import HighlightSearchText from "../../Highlighter/Highlighter";

const baseURL = process.env.REACT_APP_BASE_URL;
const CurrentAffairs = () => {
  const [categories, setCategories] = useState([]);

  let [search, setSearch] = useState("");
  const [currentAffair, setCurrentAffair] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [allCurrentAffair, setAllCurrentAffair] = useState([]);
  let [end, setEnd] = useState(null);
  let [start, setStart] = useState(null);
  // Fetch the search history from local storage
  const storedHistory = localStorage.getItem("searchHistory");
  // Parse the stored history or initialize as an empty array if no history is found
  const initialHistory = storedHistory ? JSON.parse(storedHistory) : [];
  const [history, setHistory] = useState(initialHistory);
  const isSmallScreen = window.innerWidth <= 500;
  function convertDateRange(text) {
    const months = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const match = text.match(
      /(\d+)(?:st|nd|rd|th) (\w+)(?:\s+)to (\d+)(?:st|nd|rd|th) (\w+) (\d+)/
    );
    if (!match || match.length < 6) {
      return "Invalid input format";
    }

    const [, startDay, startMonth, endDay, endMonth, year] = match;

    const startDate = new Date(
      `${year}-${months[startMonth]}-${startDay.padStart(2, "0")}`
    );
    const endDate = new Date(
      `${year}-${months[endMonth]}-${endDay.padStart(2, "0")}`
    );

    return `${startDate.toISOString().split("T")[0]}&end=${endDate.toISOString().split("T")[0]
      }`;
  }
  const callGetAPI = async (isWithDateRange = 0) => {
    let url = `${baseURL}/df/findCurrentAffairs`;
    if (searchParams.get("category") && !isWithDateRange) {
      console.log(1);
      url = `${baseURL}/df/findCurrentAffairsByCategory/${searchParams.get(
        "category"
      )}`;
    } else if ((searchParams.get("end") && searchParams.get("start")) || isWithDateRange) {
      if (start && end) {
        url = `${baseURL}/df/findCurrentAffairsByDate/${start}/${end}`;
      } else {
        url = `${baseURL}/df/findCurrentAffairsByDate/${searchParams.get("start")}/${searchParams.get("end")}`;
      }
    }
    try {
      console.log("weqweqweqwe", url);
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
        },
      });
      console.log("qwq412414134231", response.data.result);
      setCurrentAffair(response.data.result);
    } catch (error) {
      console.error("Error fetching current affairs:", error);
    }
  };
  const callGetCategories = async () => {
    try {
      const response = await axios.get(`${baseURL}/df/findSysCodVal/101`, {
        headers: {
          "Content-Type": "application/json",
          Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
        },
      });
      setCategories(response.data.result);
    } catch (error) {
      console.error("Error fetching current affairs:", error);
    }
  };
  const callGetDates = async () => {
    try {
      const response = await axios.get(`${baseURL}/df/findCurrentAffairs`, {
        headers: {
          "Content-Type": "application/json",
          Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
        },
      });
      setAllCurrentAffair(response.data.result);
    } catch (error) {
      console.error("Error fetching current affairs:", error);
    }
  };

  useEffect(() => {
    callGetAPI();
    callGetCategories();
  }, [
    searchParams.get("end"),
    searchParams.get("start"),
    searchParams.get("category"),
  ]);
  useEffect(() => {
    callGetDates();
    callGetAPI();
    callGetCategories();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      if (start != null && end != null) {
        callGetAPI(1);
      } else {
        callGetAPI();
      }
    }

    if (start != null && end != null) {
      callGetAPI(1);
    }
    const searchResults = [];
    currentAffair.forEach((item) => {
      const data = [];
      item.affairsBeans.forEach((affair) => {
        if (
          affair.currentAffairsContent
            .toLowerCase()
            .includes(search.toLowerCase())
          ||
          affair.currentAffairsHead
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          affair.title.toLowerCase().includes(search.toLowerCase())
        ) {
          console.log(affair);
          data.push(affair);
        }
      });
      if (data.length > 0) {
        item.affairsBeans = data;
        searchResults.push(item);
      }
    });
    // setSearch('');
    // setStart(null);
    // setEnd(null);
    console.log("1231231231231", searchResults);
    setCurrentAffair(searchResults);
    localStorage.setItem("history", search);
    setShowSuggestions(false); // Hide suggestions after submission
  };
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleBlur = () => {
    setShowSuggestions(false);
  };

  return isSmallScreen ? (<>
    <Container fluid className="p-0 m-0">
      <Row>
        <Col>
          <div className="Contact_heading container-fluid mb-5">
            <div className="Contact_content">Current Affairs</div>
            <div className="Contact_slashContent">Home / Current Affairs</div>
          </div>
        </Col>
      </Row>
      <Row >
        <Col>
          <div className="current-affairs-nav">
            <form className="current-affair-search " onSubmit={handleSubmit}>
              <FaSearch />
              <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
              <div
                style={{ display: showSuggestions ? "block" : "none" }}
                className="searcch-suggestions"
              >
                <span>Recent Searches: </span>
                <div
                  className="search-history"
                  onClick={() => {
                    setSearch(localStorage.getItem("history"));
                    handleSubmit();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {localStorage.getItem("history")}
                </div>
              </div>
              {/* <button type="submit"></button> */}
            </form>
          </div>
          {/* <div className="current-affairs-nav">
            <form className="current-affair-search " onSubmit={handleSubmit}>
              <FaSearch />
              <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
              <button type="submit" style={{ display: "none" }}></button>
              <div
                style={{ display: showSuggestions ? "block" : "none" }}
                className="searcch-suggestions"
              >
                <span>Recent Searches: </span>
                <div
                  className="search-history"
                  onClick={() => {
                    setSearch(localStorage.getItem("history"));
                    handleSubmit();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {localStorage.getItem("history")}
                </div>
              </div>
            </form>

            <div>
              <input
                className="news-date"
                onChange={(e) => setStart(e.target.value)}
                type="date"
                max={new Date().toISOString().split('T')[0]}
              />
              to
              <input
                className="news-date"
                onChange={(e) => setEnd(e.target.value)}
                type="date"
                max={new Date().toISOString().split('T')[0]}
              />
              {start !== null && end !== null ? (
                <Link
                  style={{
                    whiteSpace: "nowrap",
                    borderRadius: "10px",
                    background: "rgba(151, 71, 255, 0.15)",
                    padding: "0.4em 1em",
                  }}
                  to={`/current-affairs/?start=${start}&end=${end}`}
                  className="custom-hover"
                >
                  Apply Date Range
                </Link>
              ) : (
                <button
                  onClick={() => {
                    if (start === null || end === null) {
                      alert("Please select both start and end dates.");
                    }
                  }}
                  style={{
                    whiteSpace: "nowrap",
                    borderRadius: "10px",
                    background: "rgba(151, 71, 255, 0.15)",
                    padding: "0.4em 1em",
                  }}

                  className="custom-hover"
                >
                  Apply Date Range
                </button>
              )}
            </div>
          </div> */}

        </Col>
        <Col sm={6} className="d-flex align-items-center justify-content-center">
          <input
            className="news-date mt-2 mx-4"
            onChange={(e) => setStart(e.target.value)}
            type="date"
            max={new Date().toISOString().split('T')[0]}
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <div style={{ fontSize: "10px", margin: "0" }}>
            to
          </div>
        </Col>
        <Col sm={6} className="d-flex align-items-center justify-content-center">
          <input
            className="news-date mx-4"
            onChange={(e) => setEnd(e.target.value)}
            type="date"
            max={new Date().toISOString().split('T')[0]}
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-center mt-2">
          {start !== null && end !== null ? (
            <Link
              style={{
                whiteSpace: "nowrap",
                borderRadius: "10px",
                background: "rgba(151, 71, 255, 0.15)",
                padding: "0.4em 1em",
                width: "100%"
              }}
              onClick={handleSubmit}
              // to={`/current-affairs/?start=${start}&end=${end}`}
              className="custom-hover"
            >
              Apply Date Range
            </Link>
          ) : (
            <Link
              style={{
                whiteSpace: "nowrap",
                borderRadius: "10px",
                background: "rgba(151, 71, 255, 0.15)",
                padding: "0.4em 1em",
                width: "100%"
              }}
              onClick={handleSubmit}
              // to={`/current-affairs/?start=${start}&end=${end}`}
              className="custom-hover"
            >
              Apply Date Range
            </Link>
            // <button
            //   onClick={() => {
            //     handleSubmit();
            //     if (start === null || end === null) {
            //       alert("Please select both start and end dates.");
            //     }
            //   }}
            //   style={{
            //     whiteSpace: "nowrap",
            //     borderRadius: "10px",
            //     background: "rgba(151, 71, 255, 0.15)",
            //     padding: "0.4em 1em"
            //   }}

            //   className="custom-hover"
            // >
            //   Apply Date Range
            // </button>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="m-4">
          <div>
            {/* {currentAffair[0] !== undefined &&
              currentAffair[0].affairsBeans.slice(0).map((r, index) => ( */}
            {currentAffair[0] !== undefined &&
              currentAffair[0].affairsBeans.slice(0).map((r, index) => (
                <div
                  style={{
                    borderRadius: "20px",
                    border: "1px solid rgba(123, 31, 162, 0.30)",
                    padding: "1em",
                  }}
                  key={index}
                  className="curr_head_lay mb-4"
                >
                  <div
                    style={{ color: "#7B1FA2" }}
                    className="curr_head_2 mb-4"
                  >
                    {r.currentAffairsHead}: {/* {r.affairsBeans[0].title} */}
                  </div>
                  <p className="curr_para">
                    <HighlightSearchText searchText={search} text={
                      parse(
                        r.currentAffairsContent
                          .replaceAll("<p>", "")
                          .replaceAll("</p>", "")
                      )} />
                    {/* {parse(
                      r.currentAffairsContent
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    )} */}
                  </p>
                </div>
              ))}
            {currentAffair[1] !== undefined &&
              currentAffair[1].affairsBeans.slice(0).map((r, index) => (
                <div
                  style={{
                    borderRadius: "20px",
                    border: "1px solid rgba(123, 31, 162, 0.30)",
                    padding: "1em",
                  }}
                  key={index}
                  className="curr_head_lay mb-4"
                >
                  <div
                    style={{ color: "#7B1FA2" }}
                    className="curr_head_2 mb-4"
                  >
                    {r.currentAffairsHead}: {/* {r.affairsBeans[0].title} */}
                  </div>
                  <p className="curr_para">
                    <HighlightSearchText searchText={search} text={
                      parse(
                        r.currentAffairsContent
                          .replaceAll("<p>", "")
                          .replaceAll("</p>", "")
                      )} />
                    {/* {parse(
                      r.currentAffairsContent
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    )} */}
                  </p>
                </div>
              ))}
          </div>
        </Col>
        <Col className="m-4">
          <div >
            <div>
              <div className="curr_right p-4" >
                <p style={{ paddingTop: "2em" }} className="right_head2 mb-4">
                  Categories
                </p>
                {categories &&
                  categories.map((each) => (
                    <Link
                      to={`/current-affairs/?category=${each.id}`}
                      className="right_p2 custom-hover"
                    >
                      {each.label}
                    </Link>
                  ))}
                <p className="right_head mb-4">Monthly Current Affairs</p>

                {/* {allCurrentAffair.slice(0, 5).map((values, idx) => (
                  <Link
                    to={`/current-affairs/?start=${convertDateRange(
                      values.dateRangeText
                    )}`}
                    key={idx}
                    className="right_p"
                  >
                    {values.dateRangeText}
                  </Link>
                ))} */}
                <div className={`scrollable-container ${allCurrentAffair.length > 24 ? 'scrollable' : ''}`}>
                  {allCurrentAffair.slice(0, 5).map((values, idx) => (
                    <Link
                      to={`/current-affairs/?start=${convertDateRange(values.dateRangeText)}`}
                      key={idx}
                      className="right_p custom-hover d-block"
                    >
                      {values.dateRangeText}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="testimonials-poster px-4">
            <h2 style={{ textAlign: "center", color: "black", width: "60%" }}>
              Unlock knowledge, unleash potential – reach out to transform your
              learning experience.
            </h2>

            <button
              className="btn btn-primary testimonials-button"
              style={{
                background: "#71269C",
                borderRadius: "4px",
                width: "fit-content",
              }}
              onClick={() => window.location.href = "/contact"}
            >
              Get in Touch
            </button>
          </div>
        </Col>
      </Row>
    </Container >
  </>) : (
    <div className="">
      <div className="Contact_heading container-fluid mb-5">
        <div className="Contact_content">Current Affairs</div>
        <div className="Contact_slashContent">Home / Current Affairs</div>
      </div>
      <div className="current-affairs-nav">
        <form className="current-affair-search" onSubmit={handleSubmit}>
          <FaSearch />
          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          {/* <button type="submit" ></button> */}
          <div
            style={{ display: showSuggestions ? "block" : "none" }}
            className="searcch-suggestions"
          >
            <span>Recent Searches: </span>
            <div
              className="search-history"
              onClick={() => {
                setSearch(localStorage.getItem("history"));
                handleSubmit();
              }}
              style={{ cursor: "pointer" }}
            >
              {localStorage.getItem("history")}
            </div>
          </div>
        </form>

        <div>
          <input
            className="news-date"
            onChange={(e) => setStart(e.target.value)}
            type="date"
            max={new Date().toISOString().split('T')[0]}
          />
          to
          <input
            className="news-date"
            onChange={(e) => setEnd(e.target.value)}
            type="date"
            max={new Date().toISOString().split('T')[0]}
          />
          {start !== null && end !== null ? (
            <Link
              style={{
                whiteSpace: "nowrap",
                borderRadius: "10px",
                background: "rgba(151, 71, 255, 0.15)",
                padding: "0.4em 1em",
              }}
              onClick={handleSubmit}
              to={`/current-affairs/?start=${start}&end=${end}`}
              className="custom-hover"
            >
              Apply Date Range
            </Link>
          ) : (
            <Link
              style={{
                whiteSpace: "nowrap",
                borderRadius: "10px",
                background: "rgba(151, 71, 255, 0.15)",
                padding: "0.4em 1em",
              }}
              onClick={handleSubmit}
              to={`/current-affairs/?start=${start}&end=${end}`}
              className="custom-hover"
            >
              Apply Date Range
            </Link>
            // <button
            //   onClick={() => {
            //     handleSubmit();
            //     if (start === null || end === null) {
            //       alert("Please select both start and end dates.");
            //     }
            //   }}
            //   style={{
            //     whiteSpace: "nowrap",
            //     borderRadius: "10px",
            //     background: "rgba(151, 71, 255, 0.15)",
            //     padding: "0.4em 1em",
            //   }}

            //   className="custom-hover"
            // >
            //   Apply Date Range
            // </button>
          )}
        </div>
      </div>
      <div className="current-affairs-container">
        <div className="current-affairs-content-container">
          <div className="current-content">
            {/* {currentAffair[0] !== undefined &&
              currentAffair[0].affairsBeans.slice(0).map((r, index) => ( */}
            {currentAffair.length > 0 && currentAffair[0] !== undefined &&
              currentAffair[0].affairsBeans.slice(0).map((r, index) => (
                <div
                  style={{
                    borderRadius: "20px",
                    border: "1px solid rgba(123, 31, 162, 0.30)",
                    padding: "1em",
                  }}
                  key={index}
                  className="curr_head_lay mb-4"
                >
                  <div
                    style={{ color: "#7B1FA2" }}
                    className="curr_head_2 mb-4"
                  >
                    {r.currentAffairsHead}: {/* {r.affairsBeans[0].title} */}
                  </div>
                  <p className="curr_para">
                    <HighlightSearchText searchText={search} text={
                      parse(
                        r.currentAffairsContent
                          .replaceAll("<p>", "")
                          .replaceAll("</p>", "")
                      )} />
                    {/* {parse(
                      r.currentAffairsContent
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    )} */}
                  </p>
                </div>
              ))}
            {currentAffair[1] !== undefined &&
              currentAffair[1].affairsBeans.slice(0).map((r, index) => (
                <div
                  style={{
                    borderRadius: "20px",
                    border: "1px solid rgba(123, 31, 162, 0.30)",
                    padding: "1em",
                  }}
                  key={index}
                  className="curr_head_lay mb-4"
                >
                  <div
                    style={{ color: "#7B1FA2" }}
                    className="curr_head_2 mb-4"
                  >
                    {r.currentAffairsHead}: {/* {r.affairsBeans[0].title} */}
                  </div>
                  <p className="curr_para">
                    <HighlightSearchText searchText={search} text={
                      parse(
                        r.currentAffairsContent
                          .replaceAll("<p>", "")
                          .replaceAll("</p>", "")
                      )} />
                    {/* {parse(
                      r.currentAffairsContent
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    )} */}
                  </p>
                </div>
              ))}
          </div>
          <div className="current-sidebar-container">
            <div>
              <div className="curr_right p-4" >
                <p style={{ paddingTop: "2em" }} className="right_head2 mb-4">
                  Categories
                </p>
                {categories &&
                  categories.map((each) => (
                    <Link
                      to={`/current-affairs/?category=${each.id}`}
                      className="right_p2 custom-hover"
                    >
                      {each.label}
                    </Link>
                  ))}
                <p className="right_head mb-4">Monthly Current Affairs</p>
                <div className={`scrollable-container ${allCurrentAffair.length > 24 ? 'scrollable' : ''}`}>
                  {allCurrentAffair.slice(0, 23).map((values, idx) => (
                    <Link
                      to={`/current-affairs/?start=${convertDateRange(values.dateRangeText)}`}
                      key={idx}
                      className="right_p custom-hover d-block"
                    >
                      {values.dateRangeText}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-poster">
        <h2 style={{ textAlign: "center", color: "black", width: "60%" }}>
          Unlock knowledge, unleash potential – reach out to transform your
          learning experience.
        </h2>

        <button
          className="btn btn-primary testimonials-button"
          style={{
            background: "#71269C",
            borderRadius: "4px",
            width: "fit-content",
          }}
          onClick={() => window.location.href = "/contact"}
        >
          Get in Touch
        </button>
      </div>
    </div >)
};

export default CurrentAffairs;
